export const generateBlogPostPrompt = (articleDraft, keywords, toneOfVoice, complexity, language) => {
   return `
    As a professional content writer, create a comprehensive blog post of 1500+ words:

    1. Structure:
       - Follow the provided outline, expanding each point.
       - Use H2 for main sections, H3 for subsections.
       - Include multiple paragraphs per section.
       - Use bullet points and lists where appropriate.

    2. Content:
       - Provide detailed explanations and examples.
       - Address potential reader questions.
       - Use real data when available; avoid assumptions.

    3. Keywords:
       - Main keywords: ${keywords.join(', ')}
       - Include in H1, H2, and throughout the text (3% density).
       - Make keywords bold.

    4. Components:
       - Title (H1): Engaging, includes primary keyword.
       - Introduction (150-200 words): Hook and overview.
       - Body: Fully developed sections with logical flow.
       - Conclusion (100-150 words): Summary and call-to-action.

    5. Style:
       - Tone: ${toneOfVoice}
       - Complexity: Fog Index of ${complexity}
       - Conversational and engaging.

    Use rhetorical questions, analogies, and a mix of informational and entertaining content.
   `;
};