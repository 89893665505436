import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getAssistantId,
  getVectorStoreId,
  createChatSession,
  updateChatSession,
  createChatMessage,
  getChatSessions,
  createThread,
  addMessageToThread,
  runAssistant,
  checkRunStatus,
  listMessages,
  saveChatMessage,
  getChatMessages
} from '../../api';
import { UserIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';
import reisloSymbol from '../../img/logo-green-symbol.svg';
import Waiter from './waiter';
import { getOrCreateThread, sendMessageAndGetResponse } from './utilities';
import ReactMarkdown from 'react-markdown';

const SingleChat = ({ sessionId: propSessionId, threadId: propThreadId }) => {
  const { sessionId: paramSessionId } = useParams();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState(propThreadId || null);
  const [sessionId, setSessionId] = useState(propSessionId || paramSessionId || null);
  const [typingMessage, setTypingMessage] = useState('');
  const messagesEndRef = useRef(null);
  const [textareaHeight, setTextareaHeight] = useState('auto');
  const textAreaRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    if (!token) {
      navigate('/login');
    } else if (sessionId) {
      loadChatMessages();
    }
  }, [navigate, sessionId]);

  useEffect(() => {

  }, [sessionId, threadId]);

  useEffect(() => {
    const initializeNewChat = async () => {
      if (sessionId === 'new') {
        try {
          const assistantId = await getAssistantId();
          const vectorStoreId = await getVectorStoreId();
          const newThread = await createThread();
          const newSession = await createChatSession(
            'New Chat',
            newThread,
            assistantId,
            vectorStoreId
          );
          setThreadId(newThread);
          setSessionId(newSession.id);
          navigate(`/chat/${newSession.id}`, { replace: true });
        } catch (error) {
          console.error('Error initializing new chat:', error);
        }
      }
    };

    initializeNewChat();
  }, [sessionId, navigate]);

  const loadChatMessages = async () => {
    try {
      setLoading(true);
      console.log('Loading chat messages for session ID:', sessionId);

      if (sessionId === 'new') {
        console.log('New chat session, no messages to load');
        setMessages([]);
        return;
      }

      // First, fetch the chat session data
      const sessionData = await getChatSessions(1, sessionId);
      console.log('Received session data:', sessionData);

      if (Array.isArray(sessionData) && sessionData.length > 0) {
        const chatSession = sessionData.find(session => session.id === sessionId);
        if (chatSession) {
          console.log('Chat session data:', chatSession);

          // Set the thread ID from the session data
          if (chatSession.thread_id) {
            console.log('Thread ID found in session:', chatSession.thread_id);
            setThreadId(chatSession.thread_id);
          } else {
            console.warn('No thread ID found in the loaded session');
            // Create a new thread if it's missing
            const newThread = await createThread();
            if (newThread && newThread.id) {
              setThreadId(newThread.id);
              await updateChatSession(sessionId, { thread_id: newThread.id });
              console.log('Created and set new thread ID:', newThread.id);
            } else {
              throw new Error('Failed to create a new thread');
            }
          }

          // Now, fetch the messages for this session
          const messagesData = await getChatMessages(sessionId);
          console.log('Received messages data:', messagesData);

          if (Array.isArray(messagesData)) {
            setMessages(messagesData.map(message => ({
              text: message.content,
              sender: message.role
            })));
          } else {
            console.warn('No messages found or unexpected format for session ID:', sessionId);
          }
        } else {
          console.warn('No matching chat session found for ID:', sessionId);
        }
      } else {
        console.warn('No chat session data found or invalid data structure');
      }
    } catch (error) {
      console.error('Error loading chat messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    setLoading(true);
    setMessages(prevMessages => [...prevMessages, { text: input, sender: 'user' }]);

    try {
      const assistantId = await getAssistantId();
      const vectorStoreId = await getVectorStoreId();

      if (!threadId || !sessionId) {
        throw new Error('Thread or session not initialized');
      }

      // Add user message to the thread (OpenAI)
      await addMessageToThread(threadId, {
        role: 'user',
        content: input,
      });

      // Save user message to Directus
      await saveChatMessage(sessionId, {
        role: 'user',
        content: input,
        order: messages.length
      });

      setInput('');
      adjustTextAreaHeight(true);

      // Run the assistant with the current Vector Store ID
      const runId = await runAssistant(threadId, assistantId, {
        file_search: { vector_store_ids: [vectorStoreId] }
      });

      // Check run status
      let runStatus;
      do {
        const runData = await checkRunStatus(threadId, runId);
        runStatus = runData.status;
        if (runStatus === 'completed') break;
        if (runStatus === 'failed') throw new Error('Assistant run failed.');
        await new Promise(resolve => setTimeout(resolve, 2000));
      } while (runStatus === 'in_progress');

      // Fetch messages from OpenAI
      const messagesRes = await listMessages(threadId);
      const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');

      if (assistantMessage) {
        const assistantResponse = assistantMessage.content[0].text.value;

        // Save assistant message to Directus
        await saveChatMessage(sessionId, {
          role: 'assistant',
          content: assistantResponse,
          order: messages.length + 1
        });

        setMessages(prevMessages => [...prevMessages, { text: assistantResponse, sender: 'assistant' }]);

        // Update chat session title in Directus
        await updateChatSession(sessionId, {
          title: input.slice(0, 50) + '...',
          date_updated: new Date().toISOString()
        });
      }
    } catch (error) {
      console.error('Error in chat process:', error);
      setMessages(prevMessages => [...prevMessages, { text: `Error: ${error.message}`, sender: 'assistant' }]);
    } finally {
      setLoading(false);
    }
  };

  const adjustTextAreaHeight = (reset = false) => {
    if (textAreaRef.current) {
      if (reset) {
        textAreaRef.current.style.height = 'auto';
        setTextareaHeight('auto');
      } else {
        const scrollHeight = textAreaRef.current.scrollHeight;
        const maxHeight = 80; // Set your desired max height here (in pixels)
        const newHeight = Math.min(scrollHeight, maxHeight);
        setTextareaHeight(`${newHeight}px`);
      }
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    adjustTextAreaHeight();
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const renderMessage = (message) => {
    return (
      <ReactMarkdown
        components={{
          h3: ({ node, ...props }) => <h3 className="text-lg font-bold mt-4 " {...props} />,
          strong: ({ node, ...props }) => <strong className="font-bold" {...props} />,
          em: ({ node, ...props }) => <em className="italic" {...props} />,
          ul: ({ node, ...props }) => <ul className="list-disc list-inside my-4 pb-4" {...props} />,
          ol: ({ node, ...props }) => <ol className="list-decimal list-inside my-2" {...props} />,
          li: ({ node, ...props }) => <li className="ml-4" {...props} />,
          p: ({ node, ...props }) => <p className="" {...props} />,
        }}
      >
        {message.text}
      </ReactMarkdown>
    );
  };

  return (
    <div className="flex flex-col h-screen pr-8 bg-white">
      <div className="max-w-5xl mx-auto pb-48 pt-8 w-full">
        <div className="flex-1 p-4 w-full">
          <div className="p-4 rounded mb-4 h-full overflow-y-auto w-full">
            {messages.map((message, index) => (
              <div key={index} className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                <div className={`flex items-start ${message.sender === 'user' ? 'flex-row-reverse' : ''}`}>
                  <div className="flex-shrink-0 mr-4">
                    {message.sender === 'user' ? (
                      <div className="w-8 h-8 rounded-full bg-secondary-50 flex items-center justify-center">
                        <UserIcon className="w-5 h-5 text-secondary-500" />
                      </div>
                    ) : (
                      <img
                        src={reisloSymbol}
                        alt="AI avatar"
                        className="w-8 h-8 rounded-full bg-primary-100 p-1.5"
                      />
                    )}
                  </div>
                  <div className={`bg-gray-100 rounded-2xl max-w-3xl p-4 ${message.sender === 'user' ? 'mr-2 bg-primary-500 text-white rounded-tr-sm' : 'ml-2  rounded-tl-sm'}`}>
                    <div className="text-sm">
                      {renderMessage(message)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {loading && (
              <div className="mb-2 w-full">
                <div className="flex items-start w-full">
                  <div className="flex-shrink-0 mr-2">
                    <img
                      src={reisloSymbol}
                      alt="AI avatar"
                      className="w-8 h-8 rounded-full bg-gray-200 p-1"
                    />
                  </div>
                  <div className="flex-grow">
                    <Waiter />
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className="w-full left-0 mx-auto fixed pb-4 bg-white  bottom-0">
          <div className="max-w-5xl mx-auto rounded-full bg-white p-3  w-full pb-0 shadow-lg">
            <div className="flex w-full gap-0">
              <textarea
                ref={textAreaRef}
                value={input}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                style={{
                  height: textareaHeight,
                  maxHeight: '150px',
                  overflowY: 'auto'
                }}
                className="flex-grow py-4 px-4 rounded-full rounded-r-none border-r-0 resize-none mb-3"
                placeholder="Type your message..."
                rows={1}
              />
              <button
                onClick={handleSendMessage}
                className="bg-primary-500 ml-0 flex h-full text-white p-4 rounded-full rounded-l-none relative hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 active:bg-primary-700 border border-primary-500 transition duration-150 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={loading || !input.trim()}
              >
                {loading ? 'Waiting...' : (
                  <>
                    Send
                    <PaperAirplaneIcon className="w-5 h-5 ml-2 inline-block" />
                  </>
                )}
              </button>
            </div>

          </div>
          <p className="text-xs text-gray-500 mt-3 text-center max-w-4xl mx-auto ">
            Our AI assistant strives for accuracy, but may not always be perfect. For the best results, we recommend double-checking important information with the AI or through independent research. Your thoughtful engagement ensures the most reliable outcomes.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleChat;