import React from 'react';
import { CloudArrowUpIcon, ArrowLeftIcon, EllipsisVerticalIcon } from '@heroicons/react/24/outline';

const TopBar = ({ onGoBack, loading, lastSaved, formatDateUpdated, showSaveStatus = true, customMiddleContent }) => {
  return (
    <div className="flex gap-4 justify-between py-2 items-center pl-24 pr-8 border-b border-neutral-200 bg-white">
      <div className="cursor-pointer" onClick={onGoBack}>
        <div className="bg-white rounded-full p-3 flex items-center justify-center">
          <ArrowLeftIcon className="h-6 w-6" aria-hidden="true" />
        </div>
      </div>
      {showSaveStatus ? (
        <div className="flex gap-2 items-center cursor-pointer">
          {loading ? (
            <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full text-green-500" role="status">
              <span className="visually-hidden"></span>
            </div>
          ) : (
            <CloudArrowUpIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
          )}
          <span className="text-sm text-neutral-600">Saved {formatDateUpdated(lastSaved)}</span>
        </div>
      ) : customMiddleContent}
      <div>
        <div className="cursor-pointer">
          <div className="bg-white rounded-full p-3 flex items-center justify-center">
            <EllipsisVerticalIcon className="h-6 w-6" aria-hidden="true" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;