// ArticleDetailsTabs.js
import React, { useState } from 'react';
import ArticleDetailsTab from './ArticleDetailsTab';
import SEOTab from './SEOTab';
import OtherTab from './OtherTab';

const ArticleDetailsTabs = ({ articleDetails, qualityReport, onCheckQuality, isCheckingQuality }) => {
  const [activeTab, setActiveTab] = useState('seo');

  return (
    <div>
      <div className="px-2 pt-2 mt-2 border-b neutral-200 ">
      <button
          className={`py-2 px-4 ${activeTab === 'seo' ? ' bg-white   text-primary-500  border-b-2 border-primary-500' : ''}`}
          onClick={() => setActiveTab('seo')}
        >
          Quality Check
        </button>
        <button
          className={`py-2 px-4 ${activeTab === 'details' ? ' bg-white   text-primary-500  border-b-2 border-primary-500' : ''}`}
          onClick={() => setActiveTab('details')}
        >
          Article Details
        </button>

        <button
          className={`py-2 hidden px-4 ${activeTab === 'other' ? ' bg-white   text-primary-500  border-b-2  border-primary-500' : ''}`}
          onClick={() => setActiveTab('other')}
        >
          Other
        </button>
      </div>
      <div className="mt-4">
        {activeTab === 'details' && <ArticleDetailsTab details={articleDetails} />}
        {activeTab === 'seo' && (
          <SEOTab
            qualityReport={qualityReport}
            onCheckQuality={onCheckQuality}
            isCheckingQuality={isCheckingQuality}
          />
        )}
        {activeTab === 'other' && <OtherTab />}
      </div>
    </div>
  );
};

export default ArticleDetailsTabs;