// OtherTab.js
import React from 'react';

const OtherTab = () => {
  return (
    <div>
      <h2 className="text-xl font-medium mb-4">Other Details</h2>
      {/* Add any other relevant information or components here */}
    </div>
  );
};

export default OtherTab;