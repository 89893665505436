import React, { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_PROJECTS_ENDPOINT } from '../../../api';
import { XMarkIcon } from '@heroicons/react/20/solid';

const ArticleForm = ({
  articleContext,
  setArticleContext,
  keywords,
  keywordInput,
  setKeywordInput,
  contentType,
  setContentType,
  toneOfVoice,
  setToneOfVoice,
  complexity,
  setComplexity,
  language,
  setLanguage,
  handleAddKeyword,
  handleRemoveKeyword,
  toggleAdvancedSettings,
  showAdvancedSettings,
}) => {
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projectDetails, setProjectDetails] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [localArticleContext, setLocalArticleContext] = useState(articleContext);

  const [formData, setFormData] = useState({});
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');
  const navigate = useNavigate();

  // Handle textarea change
  const handleTextareaChange = (e) => {
    const newValue = e.target.value;
    setLocalArticleContext(newValue);
    setArticleContext(newValue);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      if (!token) {
        setError('Failed to fetch projects: No token found');
        return;
      }
      setLoading(true);
      try {
        const response = await axios.get(DIRECTUS_PROJECTS_ENDPOINT, {
          headers: { Authorization: `Bearer ${token}` },
          params: { filter: { owner: userId } }
        });
        setProjects(response.data.data);
        setLoading(false);
      } catch (error) {
        setError(`Failed to fetch projects: ${error.response ? error.response.statusText : error.message}`);
        setLoading(false);
      }
    };

    fetchProjects();
  }, [userId, token]);

  useEffect(() => {
    if (selectedProjectId) {
      const fetchProjectDetails = async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${DIRECTUS_PROJECTS_ENDPOINT}/${selectedProjectId}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          setProjectDetails(response.data.data);
          setLoading(false);
        } catch (error) {
          setError(`Failed to fetch project details: ${error.response ? error.response.statusText : error.message}`);
          setLoading(false);
        }
      };

      fetchProjectDetails();
    }
  }, [selectedProjectId, token]);

  const handleProjectChange = (event) => {
    const value = event.target.value;
    if (value === 'new-project') {
      navigate('/projects');
    } else {
      setSelectedProjectId(value);
    }
    setFormData({ ...formData, project: value });
  };

  // Add this useEffect to log toneOfVoice changes
  useEffect(() => {
    console.log('Tone of Voice changed:', toneOfVoice);
  }, [toneOfVoice]);

  const [selectedPlatform, setSelectedPlatform] = useState('');

  return (
    <>
      <div className=" mb-4">
        <label className=" text-sm font-medium text-gray-700 mb-1">Content Type</label>
        <select
          value={contentType}
          onChange={(e) => setContentType(e.target.value)}
          className="w-full"
        >
          <option value="blogpost">Blog Post</option>
          <option value="short ecommerce product description">Short Product Description</option>
          <option value="product review">Product Review</option>
          <option value="social media post" disabled>Coming Soon: Social Media Post</option>
        </select>

        {/* 
        <label className="block mb-2 text-sm font-medium text-slate-700">Select Project</label>
        <select required name="project" id="project-select" onChange={handleProjectChange} value={selectedProjectId}>
          <option>Select Project</option>
          {projects.map((project) => (
            <option key={project.id} value={project.id}>{project.project_name}</option>
          ))}
          <option value="new-project">Create New Project</option>
        </select>

        <label className="block text-sm font-medium text-gray-700 mb-1">Audience</label>
        <select

          className="w-full"
        > 
          <option value="blogpost">Personas 1</option>
          <option value="social media post">Personas 2</option>
          <option value="short ecommerce product description">Personas 3</option>
          <option value="product review">Personas 4</option>
        </select>
*/}
        <label className="block text-sm font-medium text-gray-700 mb-1">Article Context</label>
        <textarea
          required
          value={localArticleContext}
          onChange={handleTextareaChange}
          placeholder="What this publication will be about?"
          className="w-full"
          minLength="30"
          rows="6"
        />

        {contentType === 'social media post' ? (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Social Media Platform</label>
            <select
              value={selectedPlatform}
              onChange={(e) => setSelectedPlatform(e.target.value)}
              className="w-full"
            >
              <option value="">Select Platform</option>
              <option value="facebook">Facebook</option>
              <option value="twitter">Twitter</option>
              <option value="instagram">Instagram</option>
              <option value="linkedin">LinkedIn</option>
              <option value="tiktok">TikTok</option>
            </select>
          </div>
        ) : (
          <>
            <label className="block text-sm font-medium text-gray-700 mb-1">Select Keywords</label>
            <div className="flex flex-col mb-2">
              <input
                required
                type="text"
                value={keywordInput}
                onChange={(e) => setKeywordInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === ',' || e.key === 'Enter') {
                    e.preventDefault();
                    if (keywordInput.trim()) {
                      handleAddKeyword();
                      setKeywordInput('');
                    }
                  }
                }}
                placeholder="Enter keywords (separate with comma)"
                className="flex-1 p-2 border border-gray-300 rounded-md"
              />
            </div>
            <div className="flex flex-wrap gap-2">
              {keywords.map((keyword, index) => (
                <div key={index} className="flex items-center space-x-2 bg-primary-100 pl-3 px-2 py-2 rounded-md">
                  <span>{keyword}</span>
                  <button
                    onClick={() => handleRemoveKeyword(keyword)}
                    className="text-neutral-800"
                    type="button"
                  >
                    <XMarkIcon className="w-6" />
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      <div>
        <div className="mb-6 flex justify-between items-center cursor-pointer" onClick={toggleAdvancedSettings}>
          <span className="w-full text-base mt-2 flex justify-between p-0 ">Advanced settings
            <ChevronDownIcon className="max-w-6 " />
          </span>
        </div>
        {showAdvancedSettings && (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Tone of Voice</label>
            <select
              value={toneOfVoice}
              onChange={(e) => {
                setToneOfVoice(e.target.value);
                console.log('Tone of Voice selected:', e.target.value); // Add this line
              }}
              className="w-full"
            >
              <option value="casual">Casual</option>
              <option value="formal">Formal</option>
              <option value="friendly">Friendly</option>
              <option value="enthusiastic">Enthusiastic</option>
              <option value="informative">Informative</option>
            </select>

            <label className="block text-sm font-medium text-gray-700 mb-1">Complexity</label>
            <select
              value={complexity}
              onChange={(e) => setComplexity(e.target.value)}
              className="w-full"
            >
              <option value="fog index: 5">Very simple</option>
              <option value="fog index: 10">Simple</option>
              <option value="fog index: 15">Moderate</option>
              <option value="fog index: 20">Complex</option>
              <option value="fog index: 25">Professional</option>
            </select>

            <label className="block text-sm font-medium text-gray-700 mb-1">Language</label>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="w-full"
            >
              <option value="polski">Polish</option>
              <option value="english">English</option>
              <option value="german">German</option>
            </select>
          </div>
        )}
      </div>
    </>
  );
};

export default ArticleForm;