// ArticleDetailsTab.js
import React from 'react';

const ArticleDetailsTab = ({ details }) => {
  return (
    <div className="">
      <div className="grid grid-cols-2 overflow-hidden">
        <div className="font-semibold p-4">Status</div>
        <div className=" p-4">{details.status}</div>
        <div className="font-semibold border-t p-4">URL</div>
        <div className="border-t p-4">{details.slug}</div>
        {/* Add more details as needed */}
      </div>
    </div>
  );
};

export default ArticleDetailsTab;