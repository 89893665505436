// In Editor.js

import React, { useEffect } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Table from '@editorjs/table';
import Delimiter from '@editorjs/delimiter';
import AITool from './AITool'; // Import the new AITool


const Editor = ({ initialData, onChange, editorRef, title }) => {
  useEffect(() => {
    if (!editorRef.current) {
      let blocks = initialData?.blocks || [];

      // Check if the first block is already an H1 header
      if (!blocks.length || blocks[0].type !== 'header' || blocks[0].data.level !== 1) {
        // If not, prepend the title as an H1 header
        blocks = [
          {
            type: 'header',
            data: {
              text: title,
              level: 1
            }
          },
          ...blocks
        ];
      } else {
        // If it is, update the text of the first block
        blocks[0].data.text = title;
      }

      // Remove any [object Object] blocks
      blocks = blocks.filter(block =>
        !(block.type === 'paragraph' && block.data.text === '[object Object]')
      );

      editorRef.current = new EditorJS({
        holder: 'editorjs',
        tools: {
          header: {
            class: Header,
            inlineToolbar: ['link'],
            config: {
              placeholder: 'Enter a header',
              levels: [1, 2, 3, 4, 5, 6],
              defaultLevel: 2,
            },
          },
          delimiter: Delimiter,
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          table: {
            class: Table,
            inlineToolbar: true,
            config: {
              rows: 2,
              cols: 3,
            },
          },
          ai: {
            class: AITool,
            inlineToolbar: true,
          }  // Add the new AI tool here
        },
        data: { blocks },
        onChange: onChange,
      });
    }

    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [initialData, onChange, editorRef, title]);

  return <div id="editorjs" className="bg-white p-8"></div>;
};

export default Editor;