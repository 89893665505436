import React, { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import ProfileDropdown from './Dropdown';
import TokensStatus from './Tokens';
import Notification from './notification';
import Breadcrumbs from '../Breadcrumbs/breadcrumbsComponent';
import axios from 'axios';

const Header = ({ setSidebarOpen, userNavigation, classNames, Bars3Icon }) => {
  const [username, setUsername] = useState('');
  const [lastName, setLastName] = useState('');
  const [workspaceName, setWorkspaceName] = useState('');
  const [trialEnds, setTrialEnds] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('directus_token');
      if (!token) return;

      try {
        const response = await axios.get('https://panel.reislo.com/users/me', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['first_name', 'last_name', 'linked_workspace.workspace_name', 'trial_ends']
          }
        });

        const userData = response.data.data;
        setUsername(userData.first_name || '');
        setLastName(userData.last_name || '');
        setWorkspaceName(userData.linked_workspace?.workspace_name || '');
        setTrialEnds(userData.trial_ends ? new Date(userData.trial_ends) : null);

        localStorage.setItem('username', userData.first_name || '');
        localStorage.setItem('lastname', userData.last_name || '');
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const calculateDaysRemaining = () => {
    if (!trialEnds) return null;
    const today = new Date();
    const timeDiff = trialEnds.getTime() - today.getTime();
    const daysRemaining = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return daysRemaining > 0 ? daysRemaining : 0;
  };

  const renderTrialBanner = () => {
    const daysRemaining = calculateDaysRemaining();

    if (daysRemaining === null) return null;

    if (daysRemaining > 0) {
      return (
        <div className="bg-primary-500">
          <div className="mx-auto pt-1.5 pb-1 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-col sm:flex-row items-center justify-center text-center">
              <div className="flex items-center mb-2 sm:mb-0">
                <span className="flex rounded-lg bg-indigo-800 p-2">
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
                <p className="ml-3 font-medium text-white">
                  <span className="block sm:hidden pt-1">
                    <span className="font-bold">{daysRemaining} days</span> left in trial!
                  </span>
                  <span className="hidden sm:block pt-1">
                    Your trial is active! <span className="font-bold">{daysRemaining} days</span> remaining to explore premium features.
                  </span>
                </p>
              </div>
              <div className="mt-2 sm:mt-0 sm:ml-4">
                <a
                  href="/pricing"
                  className="hidden ml-8 flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-primary-500 shadow-sm hover:bg-indigo-50"
                >
                  Upgrade now
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="bg-indigo-600">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex  flex-1 items-center">
                <span className="flex rounded-lg bg-indigo-800 p-2">
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </span>
                <p className="ml-3  font-medium text-white">
                  <span className="md:hidden">Trial ended</span>
                  <span className="hidden md:inline">Your trial has ended. Continue enjoying our premium features!</span>
                </p>
              </div>
              <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                <a
                  href="#"
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
                >
                  Purchase Plan
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {renderTrialBanner()}
      <div className="top-0 z-40 lg:mx-auto lg:pl-8 bg-white">
        <div className="flex h-16 items-center gap-x-4 border-b border-gray-200 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-0 lg:shadow-none lg:pr-8">

          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <Breadcrumbs />
          <div className="flex flex-1 justify-end gap-x-4 lg:gap-x-4">
            <span className="mt-4 inline-flex items-center rounded-md bg-grey-50 px-3 mb-4 font-regular py-1 text-sm border border-grey-300">
              {workspaceName}</span>
            {/* <TokensStatus /> */}
            <Notification />
            <ProfileDropdown
              userNavigation={userNavigation}
              classNames={classNames}
              username={username}
              lastName={lastName}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;