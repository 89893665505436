import React, { useState, useRef, useEffect, useCallback } from 'react';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import axios from 'axios';
import { DIRECTUS_INSTANCE } from '../../../api';
import generateArticleStructure from './generateArticleStructure';
import parseAndRenderEditorContent from './parseRender';
import ArticleForm from './FirstStep';
import { useNavigate } from 'react-router-dom';
import { saveArticleToDirectus, updateArticleStatusToOngoing } from '../saveArticle';
import generateArticle from '../SingleArticle/generateArticle';
import debounce from 'lodash/debounce';
import placeholderSvg from '../../../img/placeholder.svg';
import FullScreenLoader from './FullScreenLoader';

// Add this helper function at the top of the file, outside the component
const isLocalhost = () => {
  return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
};

export default function CreateArticleDraft() {
  const [articleBody, setArticleBody] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [keywordInput, setKeywordInput] = useState('');
  const [contentType, setContentType] = useState('blogpost');
  const [toneOfVoice, setToneOfVoice] = useState('friendly');
  const [complexity, setComplexity] = useState('fog index: 10');
  const [language, setLanguage] = useState('polish');
  const [loading, setLoading] = useState(false);
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [saveError, setSaveError] = useState('');
  const [lastSaved, setLastSaved] = useState('');
  const [isDraftGenerated, setIsDraftGenerated] = useState(false);
  const [generatedArticleId, setGeneratedArticleId] = useState(null);
  const [articleData, setArticleData] = useState(null);
  const [editorHasContent, setEditorHasContent] = useState(false);
  const [articleContext, setArticleContext] = useState('');
  const [isAutosaving, setIsAutosaving] = useState(false);
  const [userHasEditedEditor, setUserHasEditedEditor] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [fullPageView, setFullPageView] = useState(false);
  const [generatedArticle, setGeneratedArticle] = useState('');
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const [loaderType, setLoaderType] = useState('');

  const navigate = useNavigate();
  const editorRef = useRef(null);

  const directusAPI = axios.create({
    baseURL: DIRECTUS_INSTANCE,
  });

  directusAPI.interceptors.request.use(config => {
    const token = localStorage.getItem('directus_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, error => Promise.reject(error));

  const title = "Create Article Draft";
  const description = "Create compelling drafts or full articles with our powerful generator. Transform your ideas into engaging content effortlessly!";

  // Replace all console.log calls with this custom logging function
  const log = (...args) => {
    if (isLocalhost()) {
      console.log(...args);
    }
  };

  const handleContinue = async () => {
    if (editorHasContent) {
      try {
        setIsFullScreenLoading(true);
        setLoaderType('article');
        const outputData = await editorRef.current.save();

        let articleId = generatedArticleId;
        let threadId = articleData?.thread_id;

        if (!articleId) {
          const newArticle = await saveArticleToDirectus({
            title: outputData.blocks[0]?.data?.text || 'Untitled',
            article_draft: JSON.stringify(outputData),
            keywords: keywords,
            content_type: contentType,
            tone_of_voice: toneOfVoice,
            fog_index: complexity,
            language: language,
            thread_id: threadId,
          });
          articleId = newArticle.data.id;
          setGeneratedArticleId(articleId);
        } else {
          await saveArticleToDirectus({
            id: articleId,
            article_draft: JSON.stringify(outputData),
            thread_id: threadId,
          });
        }

        await updateArticleStatusToOngoing(articleId);

        const response = await directusAPI.get(`/items/articles/${articleId}`);
        const currentArticleData = response.data.data;
        threadId = currentArticleData.thread_id;

        if (!threadId) {
          throw new Error('No thread_id found for this article. Please ensure the article draft was saved correctly.');
        }

        const generatedContent = await generateArticle(
          articleId,
          outputData,
          keywords,
          toneOfVoice,
          complexity,
          language,
          contentType
        );

        setGeneratedArticle(generatedContent);
        setFullPageView(true);

        await saveArticleToDirectus({
          id: articleId,
          article_body: generatedContent,
          status: 'published',
          title: outputData.blocks[0]?.data?.text || currentArticleData.title,
          slug: currentArticleData.slug,
          fog_index: complexity,
          keywords: keywords,
          content_type: contentType,
          tone_of_voice: toneOfVoice,
          language: language,
          article_draft: JSON.stringify(outputData),
          thread_id: threadId,
        }, true);

        log('Article generated and saved successfully');
        setSaveError('');
        navigate('/articles');
      } catch (error) {
        console.error('Error generating article:', error);
        setSaveError('Error generating article: ' + error.message);
      } finally {
        setIsFullScreenLoading(false);
      }
    }
  };

  const handleSave = useCallback(async (isAutosave = false) => {
    if (editorRef.current && editorRef.current.save) {
      try {
        setIsAutosaving(true);
        const outputData = await editorRef.current.save();
        
        const articleDataToSave = {
          id: generatedArticleId,
          title: outputData.blocks[0]?.data?.text || 'Untitled',
          article_draft: JSON.stringify(outputData),
          keywords: keywords,
          content_type: contentType,
          tone_of_voice: toneOfVoice,
          fog_index: complexity,
          language: language,
          thread_id: articleData?.thread_id || null,
        };



        const savedArticle = await saveArticleToDirectus(articleDataToSave);
        log('Saved article:', savedArticle);

        if (!isAutosave) {
          setGeneratedArticleId(savedArticle.data.id);
          setArticleData(savedArticle.data);
          setLastSaved(new Date().toISOString());
          setSaveError('');
          setEditorHasContent(true);
          setIsDraftGenerated(true);
        }

        log('Article draft ' + (isAutosave ? 'auto' : '') + 'saved successfully');
      } catch (error) {
        console.error('Error during saving:', error);
        setSaveError('Error during saving: ' + error.message);
      } finally {
        setIsAutosaving(false);
      }
    }
  }, [generatedArticleId, keywords, contentType, toneOfVoice, complexity, language, articleData]);

  const debouncedSave = useCallback(
    debounce(() => {
      handleSave(true);
    }, 2000),
    [handleSave]
  );

  useEffect(() => {
    if (!editorRef.current && document.getElementById('editorjs')) {
      editorRef.current = new EditorJS({
        holder: 'editorjs',
        tools: {
          header: {
            class: Header,
            inlineToolbar: ['link'],
            config: {
              placeholder: 'Enter a header',
              levels: [1, 2, 3],
              defaultLevel: 2
            }
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
        },
        onChange: () => {
          if (editorRef.current && editorRef.current.save) {
            editorRef.current.save().then((outputData) => {
              setEditorHasContent(outputData.blocks.length > 0);
              if (generatedArticleId) {
                debouncedSave();
              }
            });
          }
        },
        data: articleData?.article_draft ? 
          (typeof articleData.article_draft === 'string' ? 
            JSON.parse(articleData.article_draft) : 
            articleData.article_draft) : 
          {},
      });
    }

    return () => {
      if (editorRef.current && editorRef.current.destroy) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [debouncedSave, generatedArticleId, articleData]);


  const autoGenerateAndRender = async () => {
    setIsFullScreenLoading(true);
    setLoaderType('draft');
    try {
      const { generatedText, threadId } = await generateArticleStructure(articleContext, keywords, toneOfVoice, complexity, language, contentType);
      



      await parseAndRenderEditorContent(generatedText, editorRef);
      
      const outputData = await editorRef.current.save();
      
      const articleDataToSave = {
        title: outputData.blocks[0]?.data?.text || 'Untitled',
        article_draft: JSON.stringify(outputData),
        keywords: keywords,
        content_type: contentType,
        tone_of_voice: toneOfVoice,
        fog_index: complexity,
        language: language,
        thread_id: threadId,
      };
      


      const savedArticle = await saveArticleToDirectus(articleDataToSave);
      log('Saved article:', savedArticle);

      setGeneratedArticleId(savedArticle.data.id);
      setArticleData(savedArticle.data);
      setLastSaved(new Date().toISOString());
      setSaveError('');
      setIsDraftGenerated(true);
      setEditorHasContent(true);
      setShowEditor(true);

      if (editorRef.current) {
        const articleDraft = savedArticle.data.article_draft;
        const parsedDraft = typeof articleDraft === 'string' ? JSON.parse(articleDraft) : articleDraft;
        editorRef.current.render(parsedDraft);
      }

    } catch (error) {
      setSaveError('Error during generation or saving: ' + error.message);
      console.error('Error during generation:', error);
      alert('There was an error generating the article. Please try again or contact support if the problem persists.');
    } finally {
      setIsFullScreenLoading(false);
    }
  };

  const handleSubmit = useCallback((event) => {
    event.preventDefault();
    if (!articleContext || articleContext.trim() === '') {
      setSaveError('Article context is empty. Please provide context for the article.');
      return;
    }
    if (!userHasEditedEditor) {
      autoGenerateAndRender();
    }
  }, [articleContext, userHasEditedEditor]);


  const toggleAdvancedSettings = () => setShowAdvancedSettings(!showAdvancedSettings);

  const handleAddKeyword = () => {
    if (keywordInput && !keywords.includes(keywordInput)) {
      setKeywords([...keywords, keywordInput]);
      setKeywordInput('');
    }
  };

  const handleRemoveKeyword = (keyword) => {
    setKeywords(keywords.filter((k) => k !== keyword));
  };

  if (fullPageView) {
    return (
      <div className="min-h-screen bg-white p-8">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold mb-8">{articleData?.title || 'Generated Article'}</h1>
          <div className="prose prose-lg" dangerouslySetInnerHTML={{ __html: generatedArticle }} />
          <button
            onClick={() => setFullPageView(false)}
            className="mt-8 bg-primary-500 text-white px-6 py-2 rounded-full hover:bg-primary-600"
          >
            Back to Editor
          </button>
        </div>
      </div>
    );
  }

  if (isFullScreenLoading) return <FullScreenLoader type={loaderType} />;

  return (
    <div className="lg:pl-24  w-full">
      <form onSubmit={handleSubmit} className="mx-auto mt-16 max-w-7xl">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-3xl font-medium leading-6 text-neutral-900">{title}</h1>
            <p className="mt-5 text-base text-neutral-800 opacity-80 max-w-4xl">{description}</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex gap-4">
            <button
              type="submit"
              disabled={loading || userHasEditedEditor}
              onClick={handleSubmit}
              className={`inline-flex items-center justify-center rounded-full px-6 py-3 text-base shadow-sm focus:outline-none focus:ring-2 ${
                !userHasEditedEditor && !loading
                  ? 'bg-primary-500 cursor-pointer focus:ring-primary-500 focus:ring-offset-2 text-white'
                  : 'bg-gray-300 cursor-not-allowed text-gray-500'
              }`}>
              {loading ? 'Generating...' : contentType === 'short ecommerce product description' ? 'Create' : 'Create Draft'}
            </button>
            {contentType !== 'short ecommerce product description' && (
              <button
                onClick={handleContinue}
                disabled={!editorHasContent || loading}
                className={`inline-flex items-center justify-center rounded-full px-6 py-3 text-base text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                  editorHasContent && !loading
                    ? 'bg-primary-500 hover:bg-primary-600 focus:ring-primary-500'
                    : 'bg-gray-300 cursor-not-allowed'
                }`}>
                Generate
              </button>
            )}
          </div>
        </div>

        <div className="flex gap-8 mt-16">
          <div className="w-5/12">
            <ArticleForm
              articleContext={articleContext}
              setArticleContext={setArticleContext}
              keywords={keywords}
              keywordInput={keywordInput}
              setKeywordInput={setKeywordInput}
              contentType={contentType}
              setContentType={setContentType}
              toneOfVoice={toneOfVoice}
              setToneOfVoice={setToneOfVoice}
              complexity={complexity}
              setComplexity={setComplexity}
              language={language}
              setLanguage={setLanguage}
              loading={loading}
              handleSave={handleSave}
              handleAddKeyword={handleAddKeyword}
              handleRemoveKeyword={handleRemoveKeyword}
              toggleAdvancedSettings={toggleAdvancedSettings}
              showAdvancedSettings={showAdvancedSettings}
              saveError={saveError}
              isAutosaving={isAutosaving}
            />
          </div>
          <div className="bg-white p-8 w-full min-h-screen rounded-xl border border-neutral-200 mt-6 mb-16">
            {!editorHasContent ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-800 max-w-lg mx-auto">
                <img src={placeholderSvg} alt="Placeholder" className="mb-6" />
                <h2 className="text-3xl font-medium mb-2">No Article Draft Yet</h2>
                <p className="text-xl text-center">
                  Provide context, keywords and optional settings to generate a draft
                </p>
              </div>
            ) : null}
            <div id="editorjs" className={editorHasContent ? '' : 'hidden'}></div>
            {isAutosaving && <p className="text-sm text-gray-500 mt-2">Autosaving...</p>}
          </div>
        </div>
      </form>
    </div>
  );
}