import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { DIRECTUS_USER_JOURNEYS_ENDPOINT } from '../api'; // Ensure this path is correct

const DataContext = createContext(null);

export const DataProvider = ({ children }) => {
    const [journeys, setJourneys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('directus_token'); // Get the stored token
        if (!token) {
            setError('Authorization token is missing.');
            return;
        }
        const config = {
            headers: { Authorization: `Bearer ${token}` }
        };

        const fetchJourneys = async () => {
            try {
                setLoading(true);
                const response = await axios.get(DIRECTUS_USER_JOURNEYS_ENDPOINT, config);
                setJourneys(response.data.data); // assuming the data is in response.data.data
                setError(null);
            } catch (err) {
                setError(`Failed to fetch data: ${err.response ? err.response.data : 'Server did not respond'}`);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchJourneys();
    }, []);

    // Provide these states and methods through the context
    const contextValue = {
        journeys,
        setJourneys,
        loading,
        setLoading,
        error,
        setError,
    };

    return (
        <DataContext.Provider value={contextValue}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
