import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

const ProfileDropdown = ({ userNavigation, classNames, username, lastName }) => {
  return (
    <Menu as="div" className="relative">
      <Menu.Button className="-m-1.5 flex items-center p-1.5 mt-2">
        <span className="sr-only">Open user menu</span>
        <div className="h-8 w-8 rounded-full bg-primary-100 flex items-center justify-center text-base font-regular text-primary-500">
          {(username.substring(0, 1) + lastName.substring(0, 1)).toUpperCase()}
        </div>
        <span className="hidden lg:flex lg:items-center">
          <span className="ml-4 text-sm font-medium leading-6 text-neutral-800">
            {username}
          </span>
          <ChevronDownIcon className="ml-2 h-5 w-5 text-neutral-800" aria-hidden="true" />
        </span>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
          {userNavigation.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <a
                  href={item.href}
                  onClick={item.onClick}
                  className={classNames(
                    active ? 'bg-gray-50' : '',
                    'block px-3 py-1 text-sm leading-6 text-neutral-900'
                  )}
                >
                  {item.name}
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ProfileDropdown;
