import React, { useState, useEffect } from 'react';

const draftMessages = [
  "Analyzing input data",
  "Processing source materials",
  "Synthesizing information",
  "Crafting initial draft",
  "Refining language",
  "Structuring content",
  "Enhancing key points",
  "Polishing overall flow",
  "Adding final touches",
  "Preparing for review"
];

const finalMessages = [
  "Generating content structure",
  "Researching relevant details",
  "Crafting engaging material",
  "Optimizing for target audience",
  "Refining tone and style",
  "Ensuring coherence",
  "Deepening main concepts",
  "Polishing final version",
  "Performing quality checks",
  "Preparing for publication"
];

const FullScreenLoader = ({ type }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const [fadeState, setFadeState] = useState('in');

  useEffect(() => {
    const messages = type === 'draft' ? draftMessages : finalMessages;
    const interval = setInterval(() => {
      setFadeState('out');
      setTimeout(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        setFadeState('in');
      }, 500); // Half of the interval for smooth transition
    }, 6000);

    return () => clearInterval(interval);
  }, [type]);

  const messages = type === 'draft' ? draftMessages : finalMessages;

  return (
    <div className="fixed inset-0 bg-white z-50 flex items-center justify-center">
      <div className="text-center">
        <div className={`rounded-full h-32 w-32 mx-auto mb-4 ${type === 'draft' ? 'animate-spin border-t-2 border-b-2 border-primary-500' : 'animate-spin border-t-2 border-b-2 border-primary-500'}`}></div>
        <p className="text-xl font-semibold mb-2">Generating {type === 'draft' ? 'Draft' : 'Content'}</p>
        <p className={`text-lg text-gray-600 transition-opacity duration-500 ${fadeState === 'in' ? 'opacity-100' : 'opacity-0'}`}>
          {messages[messageIndex]}
        </p>
      </div>
    </div>
  );
};

export default FullScreenLoader;