import React from 'react';
import ReactDOM from 'react-dom';

const Notification = ({ assistantId, vectorStoreId }) => {
  return ReactDOM.createPortal(
    <div 
      className="hidden fixed bottom-0 left-0 right-0  bg-white border-t border-neutral-200 py-2 px-4 text-xs text-gray-400 z-50 whitespace-nowrap overflow-hidden text-ellipsis"
    >
      ASSISTANT ID: {assistantId} | VECTOR STORE ID: {vectorStoreId}
    </div>,
    document.body
  );
};

export default Notification;