import React, { useState } from 'react';
import axios from 'axios';

const DataStorageManager = () => {
  const [status, setStatus] = useState('');
  const [queryResults, setQueryResults] = useState(null);
  const [file, setFile] = useState(null);
  const [queryInput, setQueryInput] = useState('');

  const handleUpsertKnownVector = async () => {
    setStatus('Upserting known vector...');
    try {
      const response = await axios.post('http://localhost:5001/api/upsert-known-vector');
      setStatus(`Upsert successful. Total vectors: ${response.data.stats.totalRecordCount}`);
    } catch (error) {
      console.error('Error upserting known vector:', error);
      setStatus('Error upserting known vector. Check console for details.');
    }
  };

  const handleQueryTestVector = async () => {
    setStatus('Querying test vector...');
    setQueryResults(null);
    try {
      const response = await axios.post('http://localhost:5001/api/query-test-vector');
      setQueryResults(response.data);
      setStatus('Query completed successfully.');
    } catch (error) {
      console.error('Error querying test vector:', error);
      setStatus('Error querying test vector. Check console for details.');
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === 'text/plain') {
      setFile(selectedFile);
      setStatus('File selected: ' + selectedFile.name);
    } else {
      setStatus('Please select a .txt file');
      setFile(null);
    }
  };

  const handleFileUpload = async () => {
    if (!file) {
      setStatus('Please select a file first');
      return;
    }

    setStatus('Reading file contents...');
    const reader = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result;
      setStatus('Uploading and processing file...');
      try {
        const response = await axios.post('http://localhost:5001/api/process-text', { text });
        setStatus(`File processed successfully. Vectors created: ${response.data.vectorsGenerated}`);
      } catch (error) {
        console.error('Error processing file:', error);
        setStatus('Error processing file. Check console for details.');
      }
    };
    reader.onerror = (e) => {
      setStatus('Error reading file');
    };
    reader.readAsText(file);
  };

  const handleQuery = async () => {
    if (!queryInput) {
      setStatus('Please enter a query.');
      return;
    }

    setStatus('Querying Pinecone...');
    setQueryResults(null);
    try {
      const response = await axios.post('http://localhost:5001/api/query-pinecone', { query: queryInput });
      setQueryResults(response.data);
      setStatus('Query completed successfully.');
    } catch (error) {
      console.error('Error querying Pinecone:', error);
      setStatus('Error querying Pinecone. Check console for details.');
    }
  };

  return (
    <div className="mx-auto max-w-5xl mt-24">
      <h2>Data Storage Manager</h2>

      <button onClick={handleUpsertKnownVector}>Upsert Known Vector</button>
      <button onClick={handleQueryTestVector}>Query Test Vector</button>

      <div>
        <input type="file" accept=".txt" onChange={handleFileChange} />
        <button onClick={handleFileUpload} disabled={!file}>Upload File</button>
      </div>

      <div>
        <input
          type="text"
          value={queryInput}
          onChange={(e) => setQueryInput(e.target.value)}
          placeholder="Enter your query"
        />
        <button onClick={handleQuery}>Query Pinecone</button>
      </div>

      <div>
        <h3>Status:</h3>
        <p>{status}</p>
      </div>

      {queryResults && (
        <div>
          <h3>Query Results:</h3>
          {queryResults.matches.map((match, index) => (
            <div key={index}>
              <p>Score: {match.score}</p>
              <p>Text: {match.metadata.text}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DataStorageManager;