import React, { useEffect } from 'react';



const title = 'Pricing Table'
const description = 'This is pricing table'


export default function PricingTable() {
  useEffect(() => {
    // Load the Stripe script dynamically
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    const container = document.getElementById('stripe-pricing-table');
    let stripeElement = container.querySelector('stripe-pricing-table');

    // Load handler ensures the script and element are only added once the script is loaded
    script.onload = () => {
      if (!stripeElement) {
        stripeElement = document.createElement('stripe-pricing-table');
        stripeElement.setAttribute('pricing-table-id', 'prctbl_1PIZqFIldhZa7aOZ8xXd8JY3');
        stripeElement.setAttribute('publishable-key', 'pk_test_51PIZkwIldhZa7aOZonl5EzyXP3UZT39dgtVeYlIstIhPg7uk2kFNf0ibdgf5uuMFLBGcApN2QMyawtiBrxc6DHCl00kjFfPbLu');
        container.appendChild(stripeElement);

        console.log('Script loaded');

      }
    };

    return () => {
      // Clean up the script and the Stripe element when the component unmounts
      document.body.removeChild(script);
      if (stripeElement && stripeElement.parentNode) {
        stripeElement.parentNode.removeChild(stripeElement);
      }
    };
  }, []);

  return (
    <div className=" mx-auto max-w-6xl ">

      <div className="sm:flex-auto mt-16">
        <h1 className="text-4xl font-medium leading-6 text-gray-900 mt-8">{title}</h1>
        <p className="mt-6 text-base text-gray-600">
          {description}
        </p>
      </div>

      <div id="stripe-pricing-table" className="mt-8 shadow rounded-lg py-16 bg-white"></div>
    </div>
  );
}
