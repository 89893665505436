import React from 'react';
import ReactDOM from 'react-dom/client';
import './output.css';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { DataProvider } from './api/DataContext'; // Import the provider

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <DataProvider> {/* Wrap the App component with the DataProvider */}
        <App />
      </DataProvider>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
