// SEOTab.js
import React from 'react';
import QualityCheck from './QualityCheck';

const SEOTab = ({ qualityReport, onCheckQuality, isCheckingQuality }) => {
  return (
    <div>
      <QualityCheck
        qualityReport={qualityReport}
        onCheckQuality={onCheckQuality}
        isCheckingQuality={isCheckingQuality}
      />
    </div>
  );
};

export default SEOTab;