import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DIRECTUS_PROJECTS_ENDPOINT, DIRECTUS_PERSONAS_ENDPOINT } from '../../api';
import TopBar from '../TopBar/topBar';
import { format, parseISO } from 'date-fns';
import Snackbar from '../Atoms/snackbar';
import {
  BriefcaseIcon,
  BuildingOfficeIcon,
  LinkIcon,
  PlusIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import { UserPlusIcon } from '@heroicons/react/24/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState({
    project_name: '',
    short_description: '',
    big_picture: '',
    product_service_details: '',
    identificator: '',
    goals: '',

    industry_type: '',
    industry: '',
  });

  const [personasDetails, setPersonasDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPersonas, setLoadingPersonas] = useState(true);
  const [error, setError] = useState('');
  const [lastSaved, setLastSaved] = useState('');
  const [saveError, setSaveError] = useState('');
  const autosaveTimeoutRef = useRef(null);
  const token = localStorage.getItem('directus_token');

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [actionName, setActionName] = useState('');
  const [snackbarType, setSnackbarType] = useState('');

  useEffect(() => {
    const fetchProject = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${DIRECTUS_PROJECTS_ENDPOINT}/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProject(response.data.data);
        setLastSaved(response.data.data.date_updated);
        await fetchPersonas(response.data.data.personas);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch project details.');
        setLoading(false);
      }
    };
    fetchProject();
  }, [id, token]);

  const fetchPersonas = async (personaIds) => {
    setLoadingPersonas(true);
    try {
      const responses = await Promise.all(personaIds.map((personaId) =>
        axios.get(`${DIRECTUS_PERSONAS_ENDPOINT}/${personaId}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
      ));
      setPersonasDetails(responses.map((response) => response.data.data));
      setLoadingPersonas(false);
    } catch (error) {
      setError('Failed to fetch personas details.');
      setLoadingPersonas(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProject((prev) => ({ ...prev, [name]: value }));

    if (autosaveTimeoutRef.current) {
      clearTimeout(autosaveTimeoutRef.current);
    }
    autosaveTimeoutRef.current = setTimeout(handleSave, 2000);
  };

  const saveProjectToDirectus = async (projectData) => {
    try {
      const response = await axios.patch(`${DIRECTUS_PROJECTS_ENDPOINT}/${id}`, projectData, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
      });
      return response.data;
    } catch (error) {
      console.error('Error saving project:', error);
      throw new Error(`Error saving project: ${error.message}`);
    }
  };

  const handleSave = useCallback(async () => {
    try {
      await saveProjectToDirectus(project);
      setLastSaved(new Date().toISOString());
      console.log('Project saved successfully');
      setSaveError('');
    } catch (error) {
      console.log('Saving failed: ', error);
      setSaveError('Error saving project: ' + error.message);
    }
  }, [project, id, token]);

  function formatDateUpdated(date) {
    if (!date) return 'No update date provided';
    const now = new Date();
    const updatedDate = parseISO(date);
    const diffInSeconds = (now.getTime() - updatedDate.getTime()) / 1000;
    if (diffInSeconds < 60) return `${Math.floor(diffInSeconds)} seconds ago`;
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)} minutes ago`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)} hours ago`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)} days ago`;
    return format(updatedDate, 'dd MMM yyyy');
  }

  const goBack = () => {
    navigate('/projects');
  };

  return (
    <div className="lg:pl-24">
      <TopBar
        onGoBack={goBack}
        loading={loading}
        lastSaved={lastSaved}
        formatDateUpdated={formatDateUpdated}
        showSaveStatus={true}
        customMiddleContent={
          <h1 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {project.project_name}
          </h1>
        }
      />
      <div className="max-w-7xl mx-auto">
        {saveError && <div className="text-red-500 mb-4">{saveError}</div>}
        {showSnackbar && (
          <Snackbar
            message={snackbarMessage}
            actionName={actionName}
            type={snackbarType}
            onClose={() => setShowSnackbar(false)}
          />
        )}
        <div className="pt-8 lg:flex lg:justify-between pr-8">
          <div className="min-w-0 flex-1">
            {loading ? (
              <div className="animate-pulse">
                <div className="h-12 bg-gray-300 rounded w-3/4 mb-4"></div>
                <div className="h-6 bg-gray-300 rounded w-1/2 mb-2"></div>
              </div>
            ) : (
              <>
                <input
                  type="text"
                  name="project_name"
                  value={project.project_name}
                  onChange={handleInputChange}
                  className="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight w-full shadow-none bg-transparent border-none focus:outline-none"
                />
                <div className="pl-3 mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <BriefcaseIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {project.industry_type}
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500">
                    <BuildingOfficeIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    {project.industry}
                  </div>
                </div>
                <div className="mt-4">
                  <div className="col-span-12 p-5 bg-white border border-secondary-100 rounded w-full">
                    <textarea
                      name="short_description"
                      value={project.short_description}
                      onChange={handleInputChange}
                      className="w-full personas-details-ghost-inputs"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-6 pb-16">
          <div className="grid grid-cols-12 mt-0 gap-8 pr-8">
            {loading ? (
              <>
                <div className="col-span-6 mt-12 bg-white  rounded animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-28 bg-gray-300 rounded mb-4"></div>
                </div>
                <div className="col-span-6 mt-12 bg-white  rounded animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-28 bg-gray-300 rounded mb-4"></div>
                </div>
                <div className="col-span-6 mt-12 bg-white  rounded animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-16 bg-gray-300 rounded mb-4"></div>
                </div>
                <div className="col-span-6 mt-12 bg-white  rounded animate-pulse">
                  <div className="h-6 bg-gray-300 rounded w-1/2 mb-4"></div>
                  <div className="h-16 bg-gray-300 rounded mb-4"></div>
                </div>
              </>
            ) : (
              <>
                <div className="col-span-6 p-5 bg-white border border-secondary-100 rounded">
                  <h2 className="text-2xl font-medium mb-4">Big Picture</h2>
                  <textarea
                    name="big_picture"
                    value={project.big_picture}
                    onChange={handleInputChange}
                    className="w-full personas-details-ghost-inputs p-0"
                    rows="6"
                  />
                </div>
                <div className="col-span-6 p-5 bg-white border-secondary-100 border rounded">
                  <h2 className="text-2xl font-medium mb-4">Services Details</h2>
                  <textarea
                    name="product_service_details"
                    value={project.product_service_details}
                    onChange={handleInputChange}
                    className="w-full personas-details-ghost-inputs overflow-y-auto p-0"
                    rows="6"
                  />
                </div>
                <div className="col-span-6 p-5 bg-white border-secondary-100 border rounded">
                  <h2 className="text-2xl font-medium mb-4">Goals</h2>
                  <textarea
                    name="goals"
                    value={project.goals}
                    placeholder="What would you like to achieve?"
                    onChange={handleInputChange}
                    className="w-full personas-details-ghost-inputs p-0"
                    rows="3"
                  />
                </div>

                <div className="col-span-6 p-5 bg-white border-secondary-100 border rounded">
                  <h2 className="text-2xl font-medium mb-4">Competitors</h2>
                  <textarea
                    name="competitors"
                    value={project.competitors}
                    placeholder="Your main competitors"
                    onChange={handleInputChange}
                    className="w-full personas-details-ghost-inputs p-0"
                    rows="3"
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;