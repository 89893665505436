import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  getAssistantId,
  getVectorStoreId,
  createChatSession,
  updateChatSession,
  createChatMessage,
  getChatSessions,
  ASSISTANT_ID,
  createThread,
  addMessageToThread,
  runAssistant,
  checkRunStatus,
  listMessages,
  saveChatMessage
} from '../../api';
import Waiter from './waiter';
import reisloSymbol from '../../img/logo-green-symbol.svg';
import { UserIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid';

const Chat = () => {
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [threadId, setThreadId] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [chatSessions, setChatSessions] = useState([]);
  const [typingMessage, setTypingMessage] = useState('');
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('directus_token') || sessionStorage.getItem('directus_token');
    if (!token) {
      navigate('/login');
    } else {
      loadChatSessions();
    }
  }, [navigate]);

  const loadChatSessions = async () => {
    try {
      const sessions = await getChatSessions();
      setChatSessions(sessions);
    } catch (error) {
      console.error('Error loading chat sessions:', error);
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    setLoading(true);

    try {
      let currentThreadId = threadId;
      let currentSessionId = sessionId;

      const assistantId = await getAssistantId();
      const vectorStoreId = await getVectorStoreId();

      if (!currentThreadId) {
        // Create a new thread
        currentThreadId = await createThread();
        setThreadId(currentThreadId);


        // Create a new chat session in Directus (only once per thread)
        try {
          const newSession = await createChatSession(
            'New Chat',
            currentThreadId,
            assistantId,
            vectorStoreId
          );
          currentSessionId = newSession.id;
          setSessionId(currentSessionId);
          setChatSessions(prevSessions => [...prevSessions, newSession]);
        } catch (sessionError) {
          console.error('Error creating chat session:', sessionError);
          // Continue execution even if session creation fails
        }
      }

      // Add user message to the thread (OpenAI)
      await addMessageToThread(currentThreadId, {
        role: 'user',
        content: input,
      });

      // Save user message to Directus
      await saveChatMessage(currentSessionId, {
        role: 'user',
        content: input,
        order: messages.length
      });

      setMessages(prevMessages => [...prevMessages, { text: input, sender: 'user' }]);
      setInput('');

      // Run the assistant with the current Vector Store ID
      const runId = await runAssistant(currentThreadId, assistantId, {
        file_search: { vector_store_ids: [vectorStoreId] }
      });


      // Check run status
      let runStatus;
      do {
        const runData = await checkRunStatus(currentThreadId, runId);
        runStatus = runData.status;
        console.log('Run status:', runStatus);
        if (runStatus === 'completed') break;
        if (runStatus === 'failed') throw new Error('Assistant run failed.');
        await new Promise(resolve => setTimeout(resolve, 2000));
      } while (runStatus === 'in_progress');

      // Fetch messages from OpenAI
      const messagesRes = await listMessages(currentThreadId);
      const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');

      if (assistantMessage) {
        const assistantResponse = assistantMessage.content[0].text.value;
        
        // Save assistant message to Directus
        await saveChatMessage(currentSessionId, {
          role: 'assistant',
          content: assistantResponse,
          order: messages.length + 1
        });

        setMessages(prevMessages => [...prevMessages, { text: assistantResponse, sender: 'assistant' }]);
      }

      // Update chat session title in Directus
      if (currentSessionId) {
        try {
          await updateChatSession(currentSessionId, { 
            title: input.slice(0, 50) + '...',
            date_updated: new Date().toISOString()
          });
        } catch (updateError) {
          console.error('Error updating chat session:', updateError);
          // Continue execution even if update fails
        }
      } else {
        console.error('No session ID available for update');
      }

    } catch (error) {
      console.error('Error in chat process:', error);
      if (error.response) {

      }
      setMessages(prevMessages => [...prevMessages, { text: "Sorry, I encountered an error. Please try again.", sender: 'assistant' }]);
    } finally {
      setLoading(false);
    }
  };

  const renderContent = (content) => {
    return content.split(/(\*\*.*?\*\*)/).map((part, index) => {
      if (part.startsWith('**') && part.endsWith('**')) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      }
      return part;
    });
  };

  const parseMessage = (text) => {
    const lines = text.split('\n');
    const parsedContent = [];
    let currentList = null;

    lines.forEach(line => {
      if (line.startsWith('- ') || line.startsWith('* ')) {
        if (!currentList) {
          currentList = { type: 'ul', items: [] };
          parsedContent.push(currentList);
        }
        currentList.items.push(line.slice(2));
      } else if (line.match(/^\d+\./)) {
        if (!currentList || currentList.type !== 'ol') {
          currentList = { type: 'ol', items: [] };
          parsedContent.push(currentList);
        }
        currentList.items.push(line.slice(line.indexOf(' ') + 1));
      } else if (line.startsWith('#')) {
        const level = line.match(/^#+/)[0].length;
        parsedContent.push({ type: `h${level}`, content: line.slice(level + 1) });
        currentList = null;
      } else {
        parsedContent.push({ type: 'p', content: line });
        currentList = null;
      }
    });

    return parsedContent;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="flex flex-col h-screen pr-8 bg-white">
      <div className="max-w-5xl mx-auto pb-48 pt-8 w-full">
        <div className="flex-1 p-4 w-full">
          <div className="p-4 rounded mb-4 h-full overflow-y-auto w-full">
            {messages.map((message, index) => (
              <div key={index} className="mb-4 w-full pt-2">
                <div className="flex items-start w-full">
                  <div className="flex-shrink-0 mr-4">
                    {message.sender === 'user' ? (
                      <div className="w-8 h-8 rounded-full bg-secondary-50 flex items-center justify-center">
                        <UserIcon className="w-5 h-5 text-secondary-500" />
                      </div>
                    ) : (
                      <img
                        src={reisloSymbol}
                        alt="AI avatar"
                        className="w-8 h-8 rounded-full bg-primary-100 p-1.5"
                      />
                    )}
                  </div>
                  <div className="flex-grow pt-1">
                    {parseMessage(message.text).map((item, i) => {
                      if (item.type === 'p') {
                        return <p key={i} className="mb-2">{renderContent(item.content)}</p>;
                      } else if (item.type === 'ol') {
                        return (
                          <ol key={i} className="list-decimal list-inside mb-2">
                            {item.items.map((li, j) => <li key={j}>{renderContent(li)}</li>)}
                          </ol>
                        );
                      } else if (item.type === 'ul') {
                        return (
                          <ul key={i} className="list-disc list-inside mb-2">
                            {item.items.map((li, j) => <li key={j}>{renderContent(li)}</li>)}
                          </ul>
                        );
                      } else if (item.type.startsWith('h')) {
                        const HeadingTag = item.type;
                        return <HeadingTag key={i} className="font-medium mb-2 mt-8">{renderContent(item.content)}</HeadingTag>;
                      }
                      return null;
                    })}
                  </div>
                </div>
              </div>
            ))}
            {typingMessage && (
              <div className="mb-2 w-full">
                <div className="flex items-start w-full">
                  <div className="flex-shrink-0 mr-2">
                    <img
                      src={reisloSymbol}
                      alt="AI avatar"
                      className="w-8 h-8 rounded-full bg-gray-200 p-1"
                    />
                  </div>
                  <span className="inline-block p-2 rounded bg-gray-200 flex-grow">
                    {typingMessage}
                  </span>
                </div>
              </div>
            )}
            {loading && (
              <div className="mb-2 w-full">
                <div className="flex items-start w-full">
                  <div className="flex-shrink-0 mr-2">
                    <img
                      src={reisloSymbol}
                      alt="AI avatar"
                      className="w-8 h-8 rounded-full bg-gray-200 p-1"
                    />
                  </div>
                  <div className="flex-grow">
                    <Waiter />
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>
        <div className="w-full left-0 mx-auto fixed bottom-8">
          <div className="max-w-5xl mx-auto border rounded-xl bg-white p-4 w-full"> 
            <div className="flex w-full">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={handleKeyPress}
                className="flex-grow border p-2 rounded mr-2"
                placeholder="Type your message..."
              />
              <button
                onClick={handleSendMessage}
                className="bg-primary-500 flex h-full text-white p-4 rounded relative hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 active:bg-primary-700 transition duration-150 ease-in-out"
                disabled={loading}
              >
                {loading ? 'Waiting...' : (
                  <>
                    Send
                    <PaperAirplaneIcon className="w-5 h-5 ml-2 inline-block" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;