import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ITEMS_PER_PAGE, fetchArticles, formatDate } from '../../api';
import { ChevronLeftIcon, ChevronRightIcon, UserIcon, PlusIcon } from '@heroicons/react/20/solid';
import ContentHeader from '../Atoms/contentHeader';

const title = 'Content';
const description = 'This table provides a quick snapshot of each article details, allowing you to easily manage and review their profiles. Navigate through the list to view specific information or to make updates to existing articles.';
const primaryAction = 'Create New Content';

function ArticlesTable() {
  const [articles, setArticles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('draft');
  const navigate = useNavigate();
  const token = localStorage.getItem('directus_token');
  const userId = localStorage.getItem('user_id');

  const primaryClick = useCallback(() => navigate('/articles/create-article'), [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const data = await fetchArticles(token, userId, currentPage);
        setArticles(data.data.map(article => ({
          link: article.id,
          title: article.title,
          slug: article.slug,
          date: formatDate(article.date_created),
          status: article.status,
          owner: article.user_created?.first_name || 'Unknown',
          type: article.content_type || 'N/A',
        })));

        const totalCount = data.meta.total_count;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));

        setLoading(false);
      } catch (error) {
        console.error('Error fetching articles:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, token, userId]);


  const EmptyState = React.memo(({ onPrimaryClick }) => (
    <div className="text-center py-12">
      <UserIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No articles</h3>
      <p className="mt-1 text-sm text-gray-500">Get started by creating a new article.</p>
      <div className="mt-6">
        <button
          type="button"
          onClick={onPrimaryClick}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Article
        </button>
      </div>
    </div>
  ));


  const SkeletonRow = React.memo(() => (
    <tr className="animate-pulse">
      <td className="px-0 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-3/4"></div>
      </td>
      <td className="px-6 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="px-6 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="px-6 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="px-6 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-1/4"></div>
      </td>
      <td className="px-6 py-6 whitespace-nowrap">
        <div className="h-8 bg-gray-200 rounded w-1/2"></div>
      </td>
    </tr>
  ));

  const handlePageChange = useCallback((newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  }, [totalPages]);

  const handleRowClick = useCallback((article) => {
    if (article.status !== 'ongoing') {
      navigate(`/articles/${article.link}`);
    }
  }, [navigate]);

  const ArticleRow = React.memo(({ article, onRowClick }) => {
    const truncateTitle = (title, maxLength) => {
      if (title.length <= maxLength) return title;
      return title.slice(0, maxLength).trim() + '...';
    };

    return (
      <tr
        className={`hover:bg-neutral-50 ${article.status === 'ongoing' ? 'opacity-60' : ''}`}
        onClick={() => onRowClick(article)}
      >
        <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm sm:pl-0">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="font-medium text-gray-900">{truncateTitle(article.title, 50)}</div>
            </div>
          </div>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{article.type}</td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
          <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${article.status === 'completed' ? 'bg-green-100 text-green-800' :
            article.status === 'ongoing' ? 'bg-yellow-100 text-yellow-800' :
              article.status === 'published' ? 'bg-green-100 text-green-800' :
                'bg-gray-100 text-gray-800'
            }`}>
            {article.status}
            {article.status === 'ongoing' && (
              <svg className="animate-spin ml-1  mt-1 mr-0 h-4 w-4 text-yellow-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            )}
          </span>
        </td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{article.owner}</td>
        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">{article.date}</td>
        <td className="relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
          {article.status !== 'draft' && (
            <a href={article.link} className="text-primary-600 hover:text-primary-900">
            </a>
          )}
        </td>
      </tr>
    );
  });

  return (
    <div className="lg:pl-24">
      <div className="max-w-7xl mx-auto">
        <ContentHeader
          title={title}
          description={description}
          primaryAction={primaryAction}
          onPrimaryAction={primaryClick}
        />
        <div className="divide-y divide-gray-100 bg-white rounded border border-neutral-200 px-6 mt-8">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              {loading ? (
                <table className="min-w-full divide-y divide-gray-300">
                  <tbody>
                    {Array.from({ length: ITEMS_PER_PAGE }).map((_, index) => (
                      <SkeletonRow key={index} />
                    ))}
                  </tbody>
                </table>
              ) : articles.length === 0 ? (
                <EmptyState onPrimaryClick={primaryClick} />
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Articles
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Type
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Status
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Owner
                      </th>
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                        Creation date
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0 text-right">
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {articles.map((article) => (
                      <ArticleRow key={article.link} article={article} onRowClick={handleRowClick} />
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        {!loading && articles.length > 0 && (
          <nav className="border-t border-gray-200 px-8 py-3 flex items-center justify-between sm:px-6">
            <div className="flex-1 flex justify-between sm:hidden">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="..."
              >
                Previous
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="..."
              >
                Next
              </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{(currentPage - 1) * ITEMS_PER_PAGE + 1}</span> to <span className="font-medium">{Math.min(currentPage * ITEMS_PER_PAGE, articles.length)}</span> of{' '}
                  <span className="font-medium">{totalPages * ITEMS_PER_PAGE}</span> results
                </p>
              </div>
              <div>
                <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                  <button onClick={() => handlePageChange(currentPage - 1)} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Previous</span>
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      aria-current={currentPage === i + 1 ? 'page' : undefined}
                      onClick={() => handlePageChange(i + 1)}
                      className={`relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${currentPage === i + 1 ? 'z-10 bg-primary-500 text-primary-500' : 'text-gray-500 hover:bg-primary-50'}`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button onClick={() => handlePageChange(currentPage + 1)} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">Next</span>
                  </button>
                </nav>
              </div>
            </div>
          </nav>
        )}
      </div>
    </div>
  );
}

export default ArticlesTable;