import React, { useEffect, useState } from 'react';
const Snackbar = ({ message, type, onClose, actionName }) => {
  const [isSnackbarVisible, setSnackbarVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSnackbarVisible(false);
    }, 2000); // close the snackbar after 2 seconds
    return () => clearTimeout(timer);
  }, []);

  const closeSnackbar = () => {
    setSnackbarVisible(false);
    onClose && onClose();
  }

  if (!isSnackbarVisible) return null;

  return (
    <div className={`fixed bottom-4 left-4 z-50 text-white text-sm px-6 py-3 rounded ${type === 'error' ? 'bg-red-500' : 'bg-black bg-opacity-80'}`}>
      {message}
      <button  className="pl-4  " onClick={closeSnackbar}> {actionName}</button>
    </div>
  );
};

export default Snackbar;