const parseAndRenderEditorContent = async (rawResponse, editorRef) => {
  try {
    // Clean and parse the JSON response
    const cleanedResponse = typeof rawResponse === 'string' 
      ? rawResponse.replace(/```json|```/g, '').trim()
      : JSON.stringify(rawResponse);
    const parsedResponse = JSON.parse(cleanedResponse);
    const sections = parsedResponse.sections;

    if (!editorRef.current) {
      console.error('Editor.js is not initialized');
      return;
    }

    const editorData = {
      time: new Date().getTime(),
      blocks: []
    };

    // Helper function to add a paragraph block
    const addParagraphBlock = (text) => {
      if (text) {
        editorData.blocks.push({
          type: 'paragraph',
          data: { text }
        });
      }
    };

    // Helper function to add a header block
    const addHeaderBlock = (text, level = 2) => {
      if (text) {
        editorData.blocks.push({
          type: 'header',
          data: { text, level }
        });
      }
    };

    // Helper function to add a list block
    const addListBlock = (items) => {
      if (Array.isArray(items) && items.length > 0) {
        editorData.blocks.push({
          type: 'list',
          data: {
            style: 'unordered',
            items
          }
        });
      }
    };

    // Function to determine if content is a list or paragraph
    const isContentList = (content) => {
      return Array.isArray(content) || (typeof content === 'string' && content.includes('\n- '));
    };

    // Render sections with headers and paragraphs/lists
    if (Array.isArray(sections)) {
      sections.forEach(section => {
        addHeaderBlock(section.header);
        
        if (isContentList(section.paragraph)) {
          // If it's an array or contains bullet points, render as a list
          const listItems = Array.isArray(section.paragraph) 
            ? section.paragraph 
            : section.paragraph.split('\n- ').filter(item => item.trim());
          addListBlock(listItems);
        } else {
          // Otherwise, render as a paragraph
          addParagraphBlock(section.paragraph);
        }
      });
    }

    // Render the data using Editor.js
    await editorRef.current.render(editorData);
  } catch (error) {
    console.error('Error parsing and rendering article structure:', error);
    alert(`Failed to parse and render article structure: ${error.message}`);
  }
};

export default parseAndRenderEditorContent;