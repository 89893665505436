import React, { useState, useEffect } from 'react';
import { PhotoIcon, UserCircleIcon } from '@heroicons/react/24/solid';
import axios from 'axios';

const INPUT_CLASSES = "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6";

const FORM_FIELDS = [
  { name: 'firstName', label: 'First name', type: 'text', span: 'sm:col-span-3', autoComplete: 'given-name' },
  { name: 'lastName', label: 'Last name', type: 'text', span: 'sm:col-span-3', autoComplete: 'family-name' },
  { name: 'email', label: 'Email address', type: 'email', span: 'sm:col-span-3', autoComplete: 'email' },
  { name: 'title', label: 'Title', type: 'text', span: 'sm:col-span-3' },
  { name: 'organisationName', label: 'Organisation name', type: 'text', span: 'sm:col-span-3' },
  { name: 'location', label: 'Location', type: 'text', span: 'sm:col-span-3' },
  { name: 'description', label: 'Description', type: 'textarea', span: 'col-span-full' },
];

export default function ProfileForm() {
  const [formData, setFormData] = useState(Object.fromEntries(FORM_FIELDS.map(field => [field.name, ''])));
  const [workspace, setWorkspace] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem('directus_token');
      if (!token) return;

      try {
        const response = await axios.get('https://panel.reislo.com/users/me', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            fields: ['*', 'linked_workspace.organisation_name']
          }
        });
        console.log('User data response:', response.data);

        const userData = response.data.data;
        setFormData({
          firstName: userData.first_name || '',
          lastName: userData.last_name || '',
          email: userData.email || '',
          organisationName: userData.organisation || '',
          description: userData.description || '',
          location: userData.location || '',
          title: userData.title || '',
        });

        console.log('Linked workspace:', userData.linked_workspace);

        if (userData.linked_workspace && userData.linked_workspace.organisation_name) {
          console.log('Setting workspace name:', userData.linked_workspace.organisation_name);
          setWorkspace(userData.linked_workspace.organisation_name);
        } else if (userData.linked_workspace) {
          console.log('Workspace ID found, but name not available:', userData.linked_workspace);
          setWorkspace('Workspace name not available');
        } else {
          console.log('No linked workspace found in user data');
          setWorkspace('No workspace assigned');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load user data');
        setWorkspace('Error loading workspace information');
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    const token = localStorage.getItem('directus_token');
    if (!token) {
      setError('No authentication token found');
      setLoading(false);
      return;
    }

    try {
      await axios.patch('https://panel.reislo.com/users/me', formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError('Failed to update profile');
    } finally {
      setLoading(false);
    }
  };

  const renderField = ({ name, label, type, span, autoComplete }) => (
    <div key={name} className={span}>
      <label htmlFor={name} className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div className="mt-2">
        {type === 'textarea' ? (
          <textarea
            name={name}
            id={name}
            value={formData[name]}
            onChange={handleChange}
            rows={3}
            className={INPUT_CLASSES}
          />
        ) : (
          <input
            type={type}
            name={name}
            id={name}
            value={formData[name]}
            onChange={handleChange}
            autoComplete={autoComplete}
            className={INPUT_CLASSES}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="lg:pl-72 pt-16 mx-auto max-w-7xl pb-40">
      <form onSubmit={handleSubmit}>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900">Profile</h2>
            <p className="mt-4 text-sm leading-6 text-gray-600">
              This information will be displayed publicly so be careful what you share.
            </p>
  
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label htmlFor="photo" className="block text-sm font-medium leading-6 text-gray-900">
                  Photo
                </label>
                <div className="mt-2 flex items-center gap-x-3">
                  <UserCircleIcon className="h-12 w-12 text-gray-300" aria-hidden="true" />
                  <button
                    type="button"
                    className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  >
                    Change
                  </button>
                </div>
              </div>
  
              {/* Workspace field (non-editable) */}
              <div className="sm:col-span-3">
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Workspace
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    value={workspace}
                    readOnly
                    className={`${INPUT_CLASSES} bg-gray-100`}
                  />
                </div>
              </div>
  
              {FORM_FIELDS.map(renderField)}
            </div>
          </div>
        </div>
  
        {error && <p className="text-red-500 mt-4">{error}</p>}
  
        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
          </button>
          <button
            type="submit"
            disabled={loading}
            className="rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:opacity-50"
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
}