// AITool.js
class AITool {
  static get toolbox() {
    return {
      title: 'AI Input',
      icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
    };
  }

  constructor({ data, api }) {
    this.api = api;
    this.data = data || {};
  }

  render() {
    const wrapper = document.createElement('div');
    const input = document.createElement('input');
    const button = document.createElement('button');

    input.placeholder = 'Enter AI prompt...';
    button.textContent = 'Generate';

    button.addEventListener('click', () => this.handleAIGeneration(input.value));

    wrapper.appendChild(input);
    wrapper.appendChild(button);

    return wrapper;
  }

  async handleAIGeneration(prompt) {
    try {
      const response = await fetch('https://api.openai.com/v1/engines/davinci-codex/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer YOUR_OPENAI_API_KEY'
        },
        body: JSON.stringify({
          prompt: prompt,
          max_tokens: 100
        })
      });
  
      const data = await response.json();
      const generatedText = data.choices[0].text;
  
      // Insert the AI-generated content as a new paragraph block
      this.api.blocks.insert('paragraph', { text: generatedText });
    } catch (error) {
      console.error('Error generating AI content:', error);
      // Handle the error appropriately
    }
  }
  save(blockContent) {
    const input = blockContent.querySelector('input');
    return {
      prompt: input.value
    }
  }
}

export default AITool;