import { createThread, addMessageToThread, runAssistant, checkRunStatus, listMessages, getAssistantId } from '../../../api';

const generateStructurePrompt = (articleContext, keywords, toneOfVoice, complexity, language, contentType, selectedPlatform) => {
  let structureTemplate = '';

  switch (contentType.toLowerCase()) {
    case 'blogpost':
      structureTemplate = `
      {
        "sections": [
          {
        "header": "Use H2 at the top of each section. Include keywords to aid readability and SEO.",
          "paragraph": "Explain what should be in this section and add number of words to meet requirements"
          }
        ]
      }`;
      break;
    case 'social media post':
      structureTemplate = `
      {
        "sections": [
          {
            "header": "Attention-Grabbing Opening",
            "paragraph": "Start with a hook (20-30 words)"
          },
          {
            "header": "Main Message",
            "paragraph": "Deliver the core content (50-80 words)"
          },
          {
            "header": "Call-to-Action",
            "paragraph": "Encourage engagement (10-20 words)"
          }
        ],
        "platform": "${selectedPlatform}"
      }`;
      break;
    case 'short ecommerce product description':
      structureTemplate = `
      {
        "sections": [
          {
            "paragraph": "Just professional brief description of the product (25-50 words) for ecommerce product page"
          }
        ]
      }`;
      break;
    case 'product review':
      structureTemplate = `
      {
        "sections": [
      {
      "header": "Product name Introduction",
      "paragraph": "Introduce the product (100-150 words). Include name, manufacturer, category, purpose, key features, and initial impression."
      },
      {
      "header": "First Impressions",
      "paragraph": "Discuss packaging and initial thoughts (150-200 words). Cover unboxing, design, build quality, and standout features or concerns."
      },
      {
      "header": "Features and Performance",
      "paragraph": "Analyze features and performance (300-400 words). Detail main features, provide real-world examples, discuss effectiveness, and address any limitations."
      },
      {
      "header": "Pros and Cons - prepare table",
      "paragraph": "List advantages and disadvantages (150-200 words). Create a balanced, concise list considering various user perspectives."
      },
      {
      "header": "Comparison",
      "paragraph": "Compare with similar products (150-200 words). Identify competitors, compare features, pricing, and market position."
      },
      {
      "header": "Verdict",
      "paragraph": "Summarize and recommend (100-150 words). Recap key points, state ideal users, provide clear recommendation with any caveats."
}
]
      }`;
      break;
    default:
      structureTemplate = `
      {
        "sections": [
          {
            "header": "Use H2 at the top of each section. Include keywords to aid readability and SEO.",
            "paragraph": "Explain what should be in this section and add number of words to meet requirements"
          }
        ]
      }`;
  }

  return `Provide a content structure in PURE JSON format! Don't add your comment outside the {buckets} !!!: 
  ${structureTemplate}`;
};

const generateArticleStructure = async (articleContext, keywords, toneOfVoice, complexity, language, contentType, selectedPlatform) => {
  const isDev = process.env.NODE_ENV === 'development';

  try {
    if (!articleContext || articleContext.trim() === '') {
      throw new Error('Context is empty. Please provide context for the article.');
    }

    const threadId = await createThread();
 
    const messages = [
      {
        role: 'user',
        content: `
        You are the Copywriter. First, think about what information the reader of this article would be looking for. What sections should be included, and what is the most valuable way to organize the information? Article is top-of-tunnel content.
        Article Context: ${articleContext},
        Keywords: ${keywords.join(', ')} ,
        Tone of Voice: ${toneOfVoice},
        Complexity (Fog Index): ${complexity},
        Language: ${language},
        Content Type: ${contentType},
        ${contentType.toLowerCase() === 'social media post' ? `Platform: ${selectedPlatform}` : ''}
        `
      },
      {
        role: 'assistant',
        content: 'I will ALWAYS do search through Files I have access to and create the article structure based on this information in the specified JSON format. If there is no information in the files, dont use fake data.'
      },
      {
        role: 'user',
        content: generateStructurePrompt(articleContext, keywords, toneOfVoice, complexity, language, contentType, selectedPlatform)
      }
    ];

    for (const message of messages) {
      await addMessageToThread(threadId, message);
      isDev && console.log('Message added to thread');
    }

    const assistantId = await getAssistantId();
    const runId = await runAssistant(threadId, assistantId);


    let runData;
    while (true) {
      runData = await checkRunStatus(threadId, runId);
      isDev && console.log('Run status:', runData.status);
      if (runData.status === 'completed') {
        break;
      } else if (runData.status === 'failed') {
        isDev && console.error('Run failed. Details:', runData);
        throw new Error(`Assistant run failed: ${runData.last_error?.message || 'Unknown error'}`);
      }
      await new Promise(resolve => setTimeout(resolve, 2000));
    }


    const messagesRes = await listMessages(threadId);


    const assistantMessage = messagesRes.data.find(msg => msg.role === 'assistant');
    if (!assistantMessage || !assistantMessage.content || !assistantMessage.content.length) {
      throw new Error('No content generated by the assistant.');
    }

    const generatedText = assistantMessage.content[0].text.value;
    isDev && console.log('Generated text:', generatedText);

    // Check for file search usage
    const annotations = assistantMessage.content[0].annotations || [];
    const fileCitations = annotations.filter(annotation => annotation.file_citation);
    if (isDev) {
      if (fileCitations.length > 0) {
        isDev && console.log('File search used. Citations:', fileCitations);
      } else {
        isDev && console.log('File search not used in the response.');
      }
    }

    return { generatedText, threadId };

  } catch (error) {
    isDev && console.error('Error in generating article structure:', error);
    if (error.response) {
      isDev && console.error('API response data:', error.response.data);
    }
    throw new Error(`Failed to generate article structure: ${error.response ? error.response.data.error.message : error.message}`);
  }
};

export default generateArticleStructure;