import React from 'react';
import Chat from './chat';

const AssistantAI = () => {
  return (
    <div>
      <Chat />
    </div>
  );
};

export default AssistantAI;